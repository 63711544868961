@import "../../node_modules/bootstrap/dist/css/bootstrap.css";

$screen-xs:     480px;
$screen-xs-min: $screen-xs;
$screen-sm:     768px;
$screen-sm-min: $screen-sm;
$screen-md:     992px;
$screen-md-min: $screen-md;
$screen-lg:     1200px;
$screen-lg-min: $screen-lg;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$color-accent-1: desaturate(#c68e78, 10%);
$color-grey: #999;
$color-grey-bg: #e9e9e9;
$color-grey-bg-light: #efefef;
$color-grey-bo: #f0f0f0;

.vmiddle {
	display: inline-block;
	vertical-align: middle;
	float: none;
}

html, body {
	font-size: 8pt;

	@media screen and (min-width: $screen-md-min) {
		font-size: 10pt;
	}

}

body {
	font-family: "rooney-sans";
	font-weight: 300;
	color: black;
	position: relative;
}

h2 {
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1.5rem;
	letter-spacing: .1em;
	color: $color-accent-1;
}

h3 {
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1rem;
	letter-spacing: .1em;
}

ul {
	list-style-type: disc;
	padding: 0 1.5em;

	&.clean {
		list-style-type: none;
		padding: 0;
	}

}

a {
	color: darken($color-accent-1, 10%);

	&:hover {
		color: darken($color-accent-1, 20%);
	}

}

hr {
	margin: 1em 0;
	border-top-color: $color-grey-bo;
}

#header {
	padding: 5em 0 3em 0;
	font-weight: 300;
	background-color: white;
	font-size: 1.1rem;

	#portrait {
		position: relative;

		#anton {
			background-color: $color-grey-bg;
		}

		#mesicraka {
			position: absolute;
			top: .5em;
			left: 1em;
			width: 20%;
		}

	}

	h1 {
		font-size: 2.5rem;
		margin: 1em 0;
		text-align: center;

		@media screen and (min-width: $screen-md-min) {
			font-size: 3rem;
			text-align: left;
		}

		@media screen and (min-width: $screen-lg-min) {
			font-size: 4rem;
		}

		.title {
			font-weight: 100;
		}

		.name {
			color: $color-accent-1;
			font-size: 1.35em;
			white-space: nowrap;
			font-weight: 700;
			letter-spacing: -.025em;
		}

	}

	ul {
		list-style-type: none;
		padding: 0;

		@media scren and (max-width: $screen-xs-max) {
			text-align: center;
		}

		li {
			margin: 0 0 1.5em 0;
			padding: 0 1.5em 0 0;
			box-sizing: border-box;
			display: inline-block;
			width: 50%;
			vertical-align: top;

			@media scren and (max-width: $screen-sm-max) {
				width: 100%;
			}

			@media scren and (max-width: $screen-xs-max) {
				margin: .25em 0;
			}

		}

	}

}

#footer {
	color: $color-grey;
	margin: 3em 0;
	text-align: center;
}

.padded {
	padding: 3em 0;
	margin: 0;
}

.marged {
	margin: 3em 0;
	padding: 0;
}

.colored {
	background-color: $color-accent-1;
	color: white;

	h2 {
		color: white;
		margin-top: 0;
	}

}

.centered {
	text-align: center;
}

.navbar {
	z-index: 10;
	position: fixed;
	top: 0;
	width: 100%;
	background: none;
	border: none;
	text-align: center;
	white-space: nowrap;
	background-color: $color-grey-bg;
	border-radius: 0;
	font-size: .8rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .1em;
	height: auto;
	min-height: 0;

	.navbar-brand {

		@media scren and (min-width: $screen-sm) {
			display: none;
		}

	}

	.navbar-nav {
		text-align: center;
		width: 100%;

		li {

			@media scren and (min-width: $screen-sm) {
				display: inline-block;
				float: none;
			}

			a {
				color: darken($color-grey, 15%);
				padding: 1.5em 1em;

				&:hover {
					color: black;
				}

			}

		}

	}

}

.tableWrapper {
	border: 1px solid $color-grey-bo;
	margin: 1em 0;
	border-radius: 4px;
	overflow: hidden;

	table {
		margin: 0;

		thead, tbody, tfoot {

			tr:first-child {

				td, th {
					border-top: none;
				}

			}

		}

		p {
			margin: 0;
		}

	}

}

.table {

	&__nowrap {
		white-space: nowrap;
	}

	thead, tbody, tfoot {

		tr {

			th, td {
				border-top-color: $color-grey-bo;
				vertical-align: baseline;
			}

		}

	}

}

.hours {

	.closed {
		background-color: $color-grey-bg-light;
	}

	.day, .date {
		width: 1px;
		white-space: nowrap;
	}

	.start, .end {
		width: 50%;
	}

}

.prices {

	tr {

		td {

			p {
				color: $color-grey;

				&:first-child {
					font-weight: bold;
					color: black;
				}

			}

			&:last-child {
				text-align: right;
			}

		}

	}

}

.panel {
	margin: 1.5em 0;
}

.breadcrumbs {
	list-style-type: none;
	margin: 1rem 0;
	padding: 0;

	li {
		display: inline;

		&:last-child {

			&::after {
				content: "";
			}

		}

	}

	li::after {
		content: " / ";
	}

}
